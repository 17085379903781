import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title
} from "assets/jss/material-kit-pro-react.js";

const infoStyle = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px 0 0px"
  },
  infoAreaBig: {
    maxWidth: "1000px",
    margin: "0 auto",
    padding: "0px 0 0px",
   backgroundColor:"#fff1d0"
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  primary: {
    color: primaryColor[0]
  },
  warning: {
    color: warningColor[0]
  },
  danger: {
    color: dangerColor[0]
  },
  success: {
    color: successColor[0]
  },
  info: {
    color: infoColor[0]
  },
  rose: {
    color: roseColor[0]
  },
  gray: {
    color: grayColor[0]
  },
  blue:{
    color:"#5570DB"
  },
  yellow:{
color:"#FF9B00"
  },
  green:{
    color:"#7DC028"
  },
  litered:{
    color:"#E5584F"
  },
  orangered:{
    color:'#FA5A0C'
  },
  pink:{
    color:'#D92BA6'
  },
  purple:{
    color:"#A95D93"
  },
  icon: {
    width: "2.25rem",
    height: "2.25rem",
    fontSize: "2.25rem"
  },
  iconBig: {
    width: "2.5rem",
    height: "2.5rem",
    fontSize: "2.5rem"
  },
  descriptionWrapper: {
    color: grayColor[1],
    overflow: "hidden",
    fontSize: "16px",
      fontWeight:400,
      textAlign:"left"
  },
  descriptionWrapperBig: {
    color: grayColor[1],
   
    overflow: "hidden",
    fontSize: "20px",
      fontWeight:400,
      textAlign:"left"
  },
  title: {
    ...title,
    margin: "1.75rem 0 0.875rem !important",
    minHeight: "unset"
  },
  titlebig: {
    ...title,
    margin: "1.75rem 0 0.875rem !important",
    fontSize:24,
    minHeight: "unset"
  },
  description: {
    color: grayColor[1],
    overflow: "hidden",
    marginTop: "0px",
    "& p": {
      color: grayColor[1],
      fontSize: "12px",
      fontWeight:400
    }
  },
  descriptionbig: {
    color: grayColor[1],
    overflow: "hidden",
    marginTop: "0px",
    "& p": {
      color: grayColor[1],
      fontSize: "16px",
      fontWeight:400
    }
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  }
};

export default infoStyle;
